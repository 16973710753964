import { BrowserRouter as Router, Routes, Route, BrowserRouter } from "react-router-dom";
import { Navbar } from './components/navbar';
import { Home } from "./pages/home.jsx";
import { Footer } from './components/footer';
import { Om } from "./pages/om-oss.jsx";
import { Utleie } from "./pages/utleie.jsx";
import "./App.css";


function App() {



  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/om-oss" element={<Om />} />
          <Route path="/utleie" element={<Utleie />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;