import React from 'react';
import styled from 'styled-components';


const Header = styled.h1`
    text-align: center;
    font-size: 4rem;
`

const Listing = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8rem;
    background-color: aliceblue;
    margin-bottom: 20px;

    @media (max-width: 1406px) {
    flex-direction: column;
    gap: 3rem;
    
}
`

const Ba = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    border-right: 1px, solid, black;
    background-color: #91e6f3;
`

const Km = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    border-right: 1px, solid, black;
    background-color: #d3e5f4;
`


export const Utleie = () => {


    return (
      <>
      <Header>Utleie</Header>
      <Listing>
        <Ba>
            <h2>B-A SÆTRE</h2>
            <p>-Stilas i aluminium, av typen Strand Stillas, stillaspakke 16, som dekker 18,42m. i en etg. og har et arb. areal på 83m2.</p>
        </Ba>
        <Km>
            <h2>KM Eiendom og utleie as</h2>
            <p>-utleie av byggegjerde, 84 meter, i samarbeid med KM Eiendom og utleie as.</p>
        </Km>
      </Listing>
      </> 
    );
  }