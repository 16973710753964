import React from 'react';
import styled from 'styled-components';

const Header = styled.h1`
    text-align: center;
`

const D = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
    text-align: center;
    border-radius: 30px;

    @media (min-width: 672px){
        width: 600px;
        margin: 10px;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`


export const Om = () => {


    return (
      <>
      <Header>Om B-A Sætre. Multiservice:</Header>
      <Content>
          <D>
              <p>Jeg, Bjørn-Arvid Sætre, har jobbet litt innenfor det meste.</p>
              <p>Jeg er utdannet bilmekaniker og tømrer.</p>
              <p>Har holdt på med rep. av biler osv. som hobby, til jeg fant ut at jeg ville holde på med hus og tømrer arbeid.</p>
              <p>Jeg har jobbet som tømrer i flere år, samt i saneringsbransjen siden 2017-2023, innenfor brann, vann, kloakk og pulverskader.</p>
              <p>Jeg anser meg selv som blid, hyggelig, imøtekommende og løsningsorientert og har en mening om at "en utfordring er en ny erfaring." Sammen får man det til.</p>
          </D>
      </Content>
      </> 
    );
  }