import React from "react";
import { useNavigate } from "react-router-dom";
import "./footer.css";


export const Footer = () => {
    const navigate = useNavigate();
    
    return <>
    <div className="footer">
        <div className="footer_content">
            <div className="adress">
                <h4>Adresse:</h4>
                <p>Parkveien 7a</p>
                <p>8450 Stokmarknes</p>
            </div>
            <div className="bedrift">
                <h4>Bedrift</h4>
                <p>Org nr: 932 010 356</p>
            </div>
            <div className="info">
                <h4>Kontakt Info</h4>
                <p>Telefon: kommer</p>
                <p>Epost: kommer</p>
            </div>
        </div>
        <p className="copyright">Copyright © 2023 B-A SÆTRE</p>
    </div>
    </>
};