import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import logo from "../images/logo.png";

const LogoImage = styled.img`
    object-fit: cover;
    margin-top: 20px;
    border-radius: 20px;
    max-width: 150px;

    @media (max-width: 595px) {
    margin: 0px;
}

`


const Title = styled.h1`
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap');
margin-left: 10px;
font-family: 'Prompt', sans-serif;
font-size: 50px;
margin-top: 70px;
margin-left: 30px;
text-align: center;

@media (max-width: 240px) {
   font-size: 40px;
   
}
@media (max-width: 185px) {
   font-size: 30px;
}
@media (max-width: 592px) {
   font-size: 30px;
   margin: 0px;
}

@media (max-width: 595px) {
    margin: 0px;
    font-size: 42px;
}
`;

const LogoTitle = styled.div`
display: flex;
margin-left: 20px;
margin-right: 100px;
margin-bottom: 20px;

@media (max-width: 595px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin: auto;
}

`;

const NavbarContainer = styled.nav`
width: 100%;
background-color: rgb(19,19,19);
display: flex;
justify-content: space-between;
align-items: center;
color: white;

@media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 600px) {
    flex-wrap: wrap;
    justify-content: center;
}


`;

const Links = styled.div`
margin-right: 50px;
display: flex;
align-items: center;
justify-content: center;
text-align: center;


@media (max-width: 463px) {
   margin-left: 30px;
}

@media (max-width: 595px) {
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
}
`;

const Text = styled.p`
text-decoration: none;
color: white;
font-size: 25px;
margin-left: 20px;
@media (max-width: 565px) {
    text-decoration: underline;
}
@media (max-width: 465px) {
    margin: 7px;
    font-size: 40px;
}
`;

export const Navbar = () => {

    const navigate = useNavigate();

    
    return (
        <NavbarContainer>
            <LogoTitle>
            <LogoImage src={logo}/>
            <Title>B-A SÆTRE</Title>
            </LogoTitle>
            <Links>
                <Link  to="/"> <Text>Hjem</Text> </Link>
                <Link  to="/utleie"> <Text>Utleie</Text> </Link>
                <Link  to="/om-oss"> <Text>Om Oss</Text> </Link>
            </Links>
        </NavbarContainer>
    )
    
}