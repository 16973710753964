import React from 'react';
import styled from 'styled-components';
import bil from "../images/bil.jpg";
import { Helmet } from "react-helmet";


const ProfileImage = styled.img`
    object-fit: cover;
    margin-top: 20px;
    border-radius: 30rem;
    width: 300px;

    @media (max-width: 400px) {
      max-width: 200px;
      max-height: 200px;
    }

    @media (max-width: 800px) {
        
    }
`
const FetTekst = styled.p`
    text-align: center;
    font-weight: bold;
`

const P = styled.p`
    text-align: center;
`

const Header = styled.h1`
    text-align: center;
`

const D = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Listing = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5rem;
    background-color: aliceblue;
    flex-wrap: wrap;

    @media (max-width: 808px) {
    flex-direction: column;
    gap: 1rem;
}
`

export const Home = () => {


  return (
    <>
    <Helmet>
      <title>B-A Sætre | Hjem</title>
      <meta name="keywords" content="" />
      <meta
        name="description"
        content="Ideas page using react helmet very easy to implement "
      />
    </Helmet>
    
    <Header>B-A Sætre. Multiservice kan tilby følgende:</Header>
    <D>
        <Listing>
        <ProfileImage src={bil}/>
            <div>
            <FetTekst>Fasadearbeider som:</FetTekst>
            <P>-Dør og vindusbytte.</P>
            <P>-Bytte av bordkledning.</P>
            <P>-Etterisolering.</P>
            <P>-Gulvlegging.</P>
            <P>-Maling av hus mm.</P>
            </div>
            <div>
            <FetTekst>Innvendige arbeider som:</FetTekst>
            <P>-Dørbytte.</P>
            <P>-Bytte av veggplater.</P>
            <P>-Bytte av tak.</P>
            <P>-Isolering.</P>
            <P>-Foring og listing.</P>
            </div>
        </Listing>
       
        
        <h2>Via samarbeid med uavhengig malerfirma kan det tilbys:</h2>
        <P>Tapetsering, maling, flis legging og gulvbelegg, samt våtrom.</P>


        <FetTekst>B-A Sætre. Multiservice, kan også være behjelpelig med div. rivningsarbeider, tømming av kjellere, garasjer, loft, uthus mm. for kjøring til Reno vest for deponering, ved hjelp av egen henger eller innleid kontainer.</FetTekst>
    </D>

    <section>

    </section>
    
    
    </>
    
  );
}